export type EventHandler<Event> = (event: Event) => void;

export class EventEmitter<
  EventMap extends Record<EventTypes, any>,
  EventTypes extends keyof EventMap
> {
  private eventListeners: {
    [T in keyof EventMap]?: EventHandler<EventMap[T]>[];
  };

  constructor() {
    this.eventListeners = {};
  }

  private getHandlers<T extends EventTypes>(
    type: T
  ): EventHandler<EventMap[T]>[] {
    if (!this.eventListeners[type]) {
      this.eventListeners[type] = [];
    }

    return this.eventListeners[type]!;
  }

  public on<T extends EventTypes>(
    type: T,
    handler: EventHandler<EventMap[T]>
  ): () => void {
    const handlers = this.getHandlers(type);

    handlers.push(handler);

    return () => this.removeEventListener(type, handler);
  }

  public once<T extends EventTypes>(
    type: T,
    handler: EventHandler<EventMap[T]>
  ): () => void {
    const remove = this.on(type, (event) => {
      remove();
      handler.apply(this, [event]);
    });

    return remove;
  }

  public removeEventListener<T extends EventTypes>(
    type: T,
    handler: EventHandler<EventMap[T]>
  ): void {
    const handlers = this.getHandlers(type);

    const index = handlers.indexOf(handler);
    if (index < 0) {
      return;
    }

    if (index > -1) {
      handlers.splice(index, 1);
    }
  }

  protected emit<T extends EventTypes>(type: T, event: EventMap[T]): void {
    const handlers = this.getHandlers(type);
    for (const handler of handlers) {
      handler.apply(this, [event]);
    }
  }
}

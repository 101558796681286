import { EventEmitter } from "./events";

function uuidv4(): string {
  return crypto.randomUUID();
}

class Alexa2WebWebSocketNotReadyError extends Error {
  constructor(readyState: Alexa2WebReadyState) {
    switch (readyState) {
      case WebSocket.CONNECTING:
        super("WebSocket connecting");
      case WebSocket.CLOSING:
        super("WebSocket closing");
      case WebSocket.CLOSED:
        super("WebSocket closed");
      default:
        super("WebSocket not ready");
    }
  }
}

enum Alexa2WebReadyState {
  CONNECTING = WebSocket.CONNECTING,
  OPEN = WebSocket.OPEN,
  CLOSING = WebSocket.CLOSING,
  CLOSED = WebSocket.CLOSED,
}

interface Alexa2WebEventMap {
  message: {
    direction: "incoming" | "outgoing";
    message: { header: MessageHeader; payload: MessagePayload };
  };
  "message-sent": any;
  close: any;
  open: any;
  state:
    | "disconnected"
    | "connecting"
    | "idle"
    | "invoking"
    | "thinking"
    | "speaking"
    | "listening";

  thinking: any;
  speaking: { audio: string; caption: string };
  debug: { audio: string };
  listening: { endpoint: string; listener: Alexa2WebListener };
  idle: any;
  error: { message: string };
}

interface Alexa2WebOptions {
  groupId?: string;
  userId?: string;

  callbacks?: Partial<Alexa2WebCallbacks>;
}

interface Alexa2WebCallbacks {
  getState: () => Promise<string> | string;
  setState: (state: string) => Promise<void> | void;
  getSelection: () => Promise<string> | string;
  setSelection: (selection: string) => Promise<void> | void;
}

type MessageHeader = {
  messageId?: string;
  requestId?: string;
  source: {
    type: "User";
    id: string;
  };
  destination: {
    type: "Alexa2Web" | "Skill";
    id?: string;
  };
  name: string;
};

type MessagePayload = Record<string, any>;

export class Alexa2Web extends EventEmitter<
  Alexa2WebEventMap,
  keyof Alexa2WebEventMap
> {
  readonly endpoint: string;
  private groupId: string;
  readonly userId: string;
  readonly callbacks: Alexa2WebCallbacks;

  private ws: WebSocket;

  constructor(endpoint: string, options?: Alexa2WebOptions) {
    super();

    this.endpoint = endpoint.replace(/\/+$/, "");
    this.groupId = options?.groupId ?? uuidv4();
    this.userId = options?.userId ?? uuidv4();

    // validate options
    if (!/^https?:\/\//.test(this.endpoint)) {
      throw new Error("invalid protocol");
    }

    this.callbacks = Object.assign(
      {
        getState: () => localStorage.getItem("state") ?? "",
        setState: (state: string) => localStorage.setItem("state", state),
        getSelection: () => localStorage.getItem("selection") ?? "",
        setSelection: (selection: string) =>
          localStorage.setItem("selection", selection),
      },
      options?.callbacks
    );

    this.ws = this.connect();
  }

  private connect(): WebSocket {
    const ws = new WebSocket(
      `${this.endpoint.replace(/^http/, "ws")}/groups/${
        this.groupId
      }/websocket?userId=${this.userId}`
    );

    ws.onopen = (event) => {
      this.emit("open", event);
    };

    ws.onmessage = (event) => {
      this.onMessage(JSON.parse(event.data));
    };

    ws.onclose = (event) => {
      this.emit("close", event);
    };

    ws.onerror = () => {
      this.emit("error", { message: "Websocket error has occurred" });
    };

    return ws;
  }

  get readyState(): Alexa2WebReadyState {
    return this.ws.readyState;
  }

  close() {
    this.ws.close(1000);
  }

  private async onMessage(message: any) {
    this.emit("message", {
      direction: "incoming",
      message: message,
    });

    if (message.header.destination.type !== "User") {
      console.error("unable to handle incoming message", message);
      return;
    }

    switch (message.header.name) {
      case "Thinking":
        this.emit("thinking", {});
        break;
      case "Listening":
        this.emit("listening", {
          endpoint: `${message.payload.resource.url}?token=${message.payload.resource.token}`,
          listener: new Alexa2WebListener(
            message.payload.resource.url,
            message.payload.resource.token
          ),
        });
        break;
      case "Speaking":
        this.emit("speaking", {
          audio: `${message.payload.resource.url}?token=${message.payload.resource.token}`,
          caption: `${message.payload.caption.url}?token=${message.payload.caption.token}`,
        });
        break;
      case "Idle":
        this.emit("idle", {});
        break;
      case "Error":
        this.emit("error", { message: message.payload.error });
      case "SetStateRequest":
        this.callbacks.setState(message.payload.state);
        break;
      case "GetStateRequest":
        const state = await this.callbacks.getState();
        this.send(
          {
            requestId: message.header.messageId,
            source: {
              type: "User",
              id: this.userId,
            },
            destination: {
              type: "Skill",
              id: message.header.source.id,
            },
            name: "GetStateResponse",
          },
          {
            state: state ?? "",
          }
        );
        break;
      case "SetSelectionRequest":
        this.callbacks.setSelection(message.payload.selection);
        break;
      case "GetSelectionRequest":
        const selection = await this.callbacks.getSelection();
        this.send(
          {
            requestId: message.header.messageId,
            source: {
              type: "User",
              id: this.userId,
            },
            destination: {
              type: "Skill",
              id: message.header.source.id,
            },
            name: "GetSelectionResponse",
          },
          {
            selection: selection,
          }
        );
        break;
      default:
        console.error("unable to handle incoming message", message);
    }
  }

  /**
   *
   * @throws Alexa2WebWebSocketNotReadyError
   */
  send(header: MessageHeader, payload?: MessagePayload): void {
    if (this.ws.readyState !== WebSocket.OPEN) {
      throw new Alexa2WebWebSocketNotReadyError(this.ws.readyState);
    }

    const message = {
      header: {
        messageId: uuidv4(),
        userId: this.userId,
        ...header,
      },
      payload: payload ?? {},
    };

    this.ws.send(JSON.stringify(message));
    this.emit("message", {
      direction: "outgoing",
      message: message,
    });
  }

  invoke(locale: string, skill?: string, intent?: string): void {
    this.send(
      {
        source: {
          type: "User",
          id: this.userId,
        },
        destination: { type: "Alexa2Web" },
        name: "Invoke",
      },
      {
        locale: locale,
        skill: skill,
        intent: intent,
      }
    );
  }

  stop(): void {
    this.send({
      source: {
        type: "User",
        id: this.userId,
      },
      destination: { type: "Alexa2Web" },
      name: "Stop",
    });
  }

  /**
   * changeGroup changes the group to which the user is connected.
   * After the change, the user will no longer receive events from the old group,
   * but will be notified of events that are in the new group.
   *
   * @param groupId the id of the new group
   */
  changeGroup(groupId: string): void {
    this.ws.close();
    this.groupId = groupId;
    this.ws = this.connect();
  }
}

export class Alexa2WebListener {
  readonly endpoint: string;
  readonly token: string;

  private recorder?: MediaRecorder;

  constructor(url: string, token: string) {
    this.endpoint = url;
    this.token = token;
  }

  start(stream: MediaStream) {
    const contentType = "audio/webm;codecs=opus";

    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: contentType,
      audioBitsPerSecond: 32000,
    });
    mediaRecorder.start(500);

    let lastIndex = 0;
    mediaRecorder.addEventListener("dataavailable", async (event) => {
      const buffer = await event.data.arrayBuffer();
      const byteLength = buffer.byteLength;
      if (byteLength === 0) return;

      // TODO handle errors
      await fetch(`${this.endpoint}/${lastIndex}?token=${this.token}`, {
        method: "PUT",
        headers: {
          "Content-Type": contentType,
          "Content-Length": `${byteLength}`,
        },
        body: buffer,
      });

      lastIndex += byteLength;

      // last request
      if (mediaRecorder.state === "inactive") {
        await fetch(`${this.endpoint}/${lastIndex}?token=${this.token}`, {
          method: "PUT",
          headers: {
            "Content-Type": contentType,
            "Content-Length": "0",
          },
        });
      }
    });

    this.recorder = mediaRecorder;
  }

  stop() {
    if (!this.recorder) {
      return;
    }
    this.recorder.stop();
  }
}

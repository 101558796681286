import { Alexa2Web, Alexa2WebListener } from "./alexa2web-client";

declare global {
  interface Window { 
    Alexa2Web: typeof Alexa2Web;
    Alexa2WebListener: typeof Alexa2WebListener;
  }
}

window.Alexa2Web = Alexa2Web;
window.Alexa2WebListener = Alexa2WebListener;